@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='search']::-webkit-search-cancel-button {
	display: none;
}

@layer base {
	button {
		@apply focus:outline-none;
	}
	a {
		@apply outline-none;
	}
	.loading {
		@apply bg-gray-light rounded-lg;
	}
}
